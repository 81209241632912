/* ########## Dropdown ########## */

.dropup, .dropright, .dropdown, .dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;

  &:empty::after {
    margin-left: 0;
  }
}


.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }

  .header-search {
    display: none;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}

.dropup {
  .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: 0.125rem;
  }

  .dropdown-toggle {
    &::after {
      display: inline-block;
      margin-left: 0.255em;
      vertical-align: 0.255em;
      content: "";
      border-top: 0;
      border-right: 0.3em solid transparent;
      border-bottom: 0.3em solid;
      border-left: 0.3em solid transparent;
    }

    &:empty::after {
      margin-left: 0;
    }
  }
}

.dropright {
  .dropdown-menu {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: 0.125rem;
  }

  .dropdown-toggle {
    &::after {
      display: inline-block;
      margin-left: 0.255em;
      vertical-align: 0.255em;
      content: "";
      border-top: 0.3em solid transparent;
      border-right: 0;
      border-bottom: 0.3em solid transparent;
      border-left: 0.3em solid;
      vertical-align: 0;
    }

    &:empty::after {
      margin-left: 0;
    }
  }
}

.dropleft {
  .dropdown-menu {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: 0.125rem;
  }

  .dropdown-toggle {
    &::after {
      display: inline-block;
      margin-left: 0.255em;
      vertical-align: 0.255em;
      content: "";
      display: none;
    }

    &::before {
      display: inline-block;
      margin-right: 0.255em;
      vertical-align: 0.255em;
      content: "";
      border-top: 0.3em solid transparent;
      border-right: 0.3em solid;
      border-bottom: 0.3em solid transparent;
      vertical-align: 0;
    }

    &:empty::after {
      margin-left: 0;
    }
  }
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e8e8f7;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #647194;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  padding: 8px 15px;
  font-size: 13px;
  border: 0;

  &:hover, &:focus {
    color: #141c2b;
    text-decoration: none;
    background-color: #f1f2f9;
  }

  &.disabled, &:disabled {
    color: #a8afc7;
    pointer-events: none;
    background-color: transparent;
  }
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.76562rem;
  color: #a8afc7;
  white-space: nowrap;
  padding-left: 15px;
  padding-right: 15px;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #8f9cc0;
}

.dropdown-menu {
  .main-form-search {
    flex: 1;
    width: 100%;
    position: relative;
  }

  .main-header-center {
    .select2-container--default .select2-selection--single {
      border-radius: 6px 0 0 6px;
      background: #eaebfa;
      height: 40px;
      width: 200px !important;
    }

    .search-btn {
      padding: 0.375rem 0.85rem 0.4rem 0.70rem;
      border-radius: 0 6px 6px 0;
      color: $white;
      display: inline-block;
    }
  }

  .main-form-search {
    .btn, .sp-container button {
      display: inline-block;
      position: initial;
      padding: 0.375rem 0.85rem 0.4rem 0.70rem;
      border-radius: 0 6px 6px 0;
      color: $white;
    }
  }

  .sp-container .main-form-search button {
    display: inline-block;
    position: initial;
    padding: 0.375rem 0.85rem 0.4rem 0.70rem;
    border-radius: 0 6px 6px 0;
    color: $white;
  }

  .main-header-center .form-control {
    background: #eaebfa;
    border: 1px solid #e6eaff;
    transition: none;
    height: 40px;
    border-radius: 0;
  }

  .input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
  }
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;

  &::after {
    margin-left: 0;
  }
}

.dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.dropdown.header-message .nav-link.icon {
  font-size: 18px;
}

.dropdown-menu {
  padding: 0;
  border-width: 1px;
  border-radius: 4px;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.875rem;
  color: #8f9cc0;
  text-align: left;
  list-style: none;
  background-color: $white;
  background-clip: padding-box;
  border: 1px solid $white;
  border-radius: 0px;
  box-shadow: -8px 12px 18px 0 rgba(21, 21, 62, 0.3);
  
  &[x-placement^="top"], &[x-placement^="right"], &[x-placement^="bottom"], &[x-placement^="left"] {
      right: auto;
      bottom: auto;
  }
}

/* ########## Dropdown ########## */